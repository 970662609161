import { Controller } from "@hotwired/stimulus"
import $ from 'jquery'

// window.onload = function() {
//   var reloading = sessionStorage.getItem("reloading");
//   if (reloading) {
//       sessionStorage.removeItem("reloading");
//       myFunction();
//   }
// }

// function reloadP() {
//     sessionStorage.setItem("reloading", "true");
//     document.location.reload();
// }


export default class extends Controller {
  static values = {
    currentPage: {type: Number, default: 1},
    currentSlug: String,
    totalPage: Number,
    perPage: {type: Number, default: 6}
  }

  connect() {
    console.log('stimulus connected')
    this.initial()
  }

  async initial(){
    this.currentSlugValue = $('.post-categories-content .category-tab button')[0].value
    var data = await this.fetchData(this.currentSlugValue, this.currentPageValue)
    if ( data?.items.length == 0){
      this.showNotFoundMessage()
      return
    } else {
      this.appendArticleElements(data.items)
      this.totalPageValue = data.total_pages
      this.createPagination(this.totalPageValue, 1)
    }
  }

  // update with slug
  async updateWithSlug(event){
    this.currentSlugValue = event.target.value
    this.currentPageValue = 1
    if (event.target.classList.contains('active')){
      return
    }

    this.activeTab(event.target)
    var data = await this.fetchData(this.currentSlugValue, this.currentPageValue)
    this.totalPageValue = data.total_pages

    if ( data?.items.length == 0){
      $('.post-categories-content .post-categories .contents').empty()
      $('.post-categories-content .pagination nav').hide()
      this.showNotFoundMessage()
      return
    }

    $('.post-categories-content .pagination nav').show()
    this.createPagination(this.totalPageValue, 1)
    this.hideNotFoundMessage()
    this.appendArticleElements(data.items)
  }


  // update with page
  async updateWithPage(event){
    this.currentPageValue = event.target.text
    if (event.target.classList.contains('pagi-active')){
      return
    }
    this.activePage(event.target)

    // $('.post-categories-content .pagination .pagi-active').text
    var data = await this.fetchData(this.currentSlugValue, this.currentPageValue)
    if ( data?.items.length == 0){
      $('.post-categories-content .post-categories .contents').empty()
      $('.post-categories-content .pagination nav').hide()
      this.showNotFoundMessage()
      return
    }
    this.createPagination(this.totalPageValue, this.currentPageValue)
    this.hideNotFoundMessage()
    this.appendArticleElements(data.items)
  }

  activeTab(element){
    $('.post-categories-content .category-tabs button.active').removeClass('active')
    $(element).addClass('active')
  }

  fetchData(slug, page, per=this.perPageValue){
    if (slug) {
      var url = `api/v1/content/categories/${slug}/posts?page=${page}&per_page=${per}`
      return fetch(url ,{
        method: 'GET',
        headers: {
          "Accept": "application/json",
          "Content-Type": "application/json",
          "CLIENT_ID": 'SAGRI',
        }
      }).then(async res => {
        const data = await res.json()
        return data.data
      })
    }
  }

  buildArticleElement(data) {
    return  `
      <article>
        <img class="object-cover rounded-lg h-64 w-full" src="${data.feature_image}"/>
        <p class='mt-2 mb-0'>${data.updated_at}</p>
        <h2 class="line-clamp-2 mb-2 mt-2 text-xl font-semibold leading-[1.875rem] tracking-tight text-gray-900"><a href="/${data.slug}">${data.title}</a></h2>
        <p class="mb-5 line-clamp-4 font-light text-gray-500 dark:text-gray-400 text-justify">${data.expert}</p>
      </article>
    `
  }

  appendArticleElements(items){
    var articlesElements = ``
    items.forEach((item) => {
      articlesElements += this.buildArticleElement(item)
    })

   $('.post-categories-content .post-categories .contents').html(articlesElements)
  }

  // Function to show the message
  showNotFoundMessage() {
    var notFoundElement = $('.post-categories-content .not-found-message')
    if (notFoundElement.hasClass('hidden')){
      notFoundElement.removeClass('hidden')
    }
  }
  
  // Function to hide the message
  hideNotFoundMessage() {
    var notFoundElement = $('.post-categories-content .not-found-message')
    if (notFoundElement.hasClass('hidden')){
      return
    }
    notFoundElement.addClass('hidden')
  }

  // Paginate----------------------------------------
  createPagination(totalPage, currentPage){
    $('.post-categories-content .pagination nav').empty()
    if (this.hasTotalPageValue) {
      var html = ``
      if (totalPage === 0){
        $('.post-categories-content .pagination nav').html(html)
      } else {
        if (totalPage === 1){
          html = this.buildPaginationElement(1)
        } else if (totalPage > 1 && totalPage <= 5)  {
            for (var i = 1; i <= totalPage; i ++ ){
              html += this.buildPaginationElement(i)
            }
            html = this.previousElement() + html + this.nextElement() 
        } else {
          if (currentPage <= 3){
            html += this.previousElement()
            for (var i = 1; i <= 5; i ++ ){
              html += this.buildPaginationElement(i)
            }
            if (totalPage === 6){
              html += this.buildPaginationElement(6)

            } else {
              html += this.threeDot()
              html += this.buildPaginationElement(totalPage)
              html += this.nextElement()
            }
          } else {
            if (totalPage > currentPage + 2 ) {
              for (var i = currentPage -2 ; i <= currentPage + 2; i ++ ){
                html += this.buildPaginationElement(i)
              }
              html += this.threeDot()
            } else {
              for (var i = currentPage -2 ; i <= totalPage; i ++ ){
                html += this.buildPaginationElement(i)
              }
            }
            html = this.previousElement() + this.threeDot() + html + this.nextElement() 
          }           
        }
        $('.post-categories-content .pagination nav').append(html)
      }
    }
  }

  threeDot(){
    return `
      <a href="javascript:;" class="relative items-center px-4 py-2 text-sm font-semibold text-gray-900  md:inline-flex">...</a>
    `
  }

  previousElement(){
    return `
      <a href="javascript:;" data-action="click->tab#goPreviousPage" class="previous relative inline-flex items-center rounded-l-md px-2 py-2 text-gray-400 hover:bg-gray-50 ">
        <span class="sr-only"> < </span>
        <svg class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
          <path fill-rule="evenodd" d="M12.79 5.23a.75.75 0 01-.02 1.06L8.832 10l3.938 3.71a.75.75 0 11-1.04 1.08l-4.5-4.25a.75.75 0 010-1.08l4.5-4.25a.75.75 0 011.06.02z" clip-rule="evenodd" />
        </svg>
      </a>
      `
  }

  async goPreviousPage(){
    if (this.currentPageValue === 1) return
    this.currentPageValue -= 1
    this.createPagination(this.totalPageValue, this.currentPageValue)
    var data = await this.fetchData(this.currentSlugValue, this.currentPageValue)
    if ( data?.items.length == 0){
      $('.post-categories-content .post-categories .contents').empty()
      this.showNotFoundMessage()
      return
    }
    this.createPagination(this.totalPageValue, this.currentPageValue)
    this.hideNotFoundMessage()
    this.appendArticleElements(data.items)
  }

  async goNextPage(){
    if (this.currentPageValue === this.totalPageValue) return
    this.currentPageValue += 1
    this.createPagination(this.totalPageValue, this.currentPageValue)
    var data = await this.fetchData(this.currentSlugValue, this.currentPageValue)
    if ( data?.items.length == 0){
      $('.post-categories-content .post-categories .contents').empty()
      this.showNotFoundMessage()
      return
    }
    this.createPagination(this.totalPageValue, this.currentPageValue)
    this.hideNotFoundMessage()
    this.appendArticleElements(data.items)
  }

  nextElement(){
    return `
      <a href="javascript:;" data-action="click->tab#goNextPage" class="relative next inline-flex items-center rounded-r-md px-2 py-2 text-gray-400">
        <span class="sr-only"> > </span>
        <svg class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
          <path fill-rule="evenodd" d="M7.21 14.77a.75.75 0 01.02-1.06L11.168 10 7.23 6.29a.75.75 0 111.04-1.08l4.5 4.25a.75.75 0 010 1.08l-4.5 4.25a.75.75 0 01-1.06-.02z" clip-rule="evenodd" />
        </svg>
      </a>
    `
  }


  buildPaginationElement(pageNumber){
    return `
      <a href="javascript:;" data-action="click->tab#updateWithPage" value='${pageNumber}' class="page-${pageNumber} ${pageNumber === this.currentPageValue ? 'pagi-active' : ''} relative hidden items-center px-4 py-2 text-sm text-gray-900 rounded-full md:inline-flex">${pageNumber}</a>
    `
  }

  activePage(element){
    $('.post-categories-content .pagination a.pagi-active').removeClass('pagi-active')
    $(element).addClass('pagi-active')
  }
}
