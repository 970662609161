// styles
import "../css/admin.css";

require.context('../images', true)

// libs
import 'flowbite';
import "./components/particles";
import "../controllers"

window.onload = () => {
  const nav = document.querySelector("nav");
  const navHeight = 70;
  // the point the scroll starts from (in px)
  let lastScrollY = 0;
  // how far to scroll (in px) before triggering
  const delta = 10;
  
  // function to run on scrolling
  function scrolled() {
    let sy = window.scrollY;
    
    // only trigger if scrolled more than delta
    if (Math.abs(lastScrollY - sy) > delta) {
      // scroll down -> hide nav bar
      if (sy > lastScrollY && sy > navHeight) {
        nav.classList.add("hidden");
      } 
      // scroll up -> show nav bar
      else if (sy < lastScrollY) {
        nav.classList.remove("hidden");
      }
     // update current scroll point
     lastScrollY = sy 
    }
  }
  
  // Add event listener & debounce so not constantly checking for scroll
  let didScroll = false;
  window.addEventListener("scroll", function(e){
    didScroll = true;
  });
  
  setInterval(function() {
    
    if (didScroll) {
      scrolled();
      didScroll = false;
     }
  }, 250)
    const particle = document.getElementById("particles-js")
    if(particle) {
      particlesJS('particles-js',
        {
          "particles": {
            "number": {
              "value": 80,
              "density": {
                "enable": true,
                "value_area": 800
              }
            },
            "color": {
              "value": "#ffffff"
            },
            "shape": {
              "type": "circle",
              "stroke": {
                "width": 0,
                "color": "#000000"
              },
              "polygon": {
                "nb_sides": 5
              },
              "image": {
                "src": "img/github.svg",
                "width": 100,
                "height": 100
              }
            },
            "opacity": {
              "value": 0.5,
              "random": false,
              "anim": {
                "enable": false,
                "speed": 1,
                "opacity_min": 0.1,
                "sync": false
              }
            },
            "size": {
              "value": 5,
              "random": true,
              "anim": {
                "enable": false,
                "speed": 40,
                "size_min": 0.1,
                "sync": false
              }
            },
            "line_linked": {
              "enable": true,
              "distance": 150,
              "color": "#ffffff",
              "opacity": 0.4,
              "width": 1
            },
            "move": {
              "enable": true,
              "speed": 6,
              "direction": "none",
              "random": false,
              "straight": false,
              "out_mode": "out",
              "attract": {
                "enable": false,
                "rotateX": 600,
                "rotateY": 1200
              }
            }
          },
          "interactivity": {
            "detect_on": "canvas",
            "events": {
              "onhover": {
                "enable": true,
                "mode": "repulse"
              },
              "onclick": {
                "enable": true,
                "mode": "push"
              },
              "resize": true
            },
            "modes": {
              "grab": {
                "distance": 400,
                "line_linked": {
                  "opacity": 1
                }
              },
              "bubble": {
                "distance": 400,
                "size": 40,
                "duration": 2,
                "opacity": 8,
                "speed": 3
              },
              "repulse": {
                "distance": 200
              },
              "push": {
                "particles_nb": 4
              },
              "remove": {
                "particles_nb": 2
              }
            }
          },
          "retina_detect": true,
          "config_demo": {
            "hide_card": false,
            "background_color": "#b61924",
            "background_image": "",
            "background_position": "50% 50%",
            "background_repeat": "no-repeat",
            "background_size": "cover"
          }
        }
      );
    }

    // set the modal menu element
    const $targetEl = document.getElementById('default-modal');
    // if($targetEl){
    // options with default values
    const options = {
        placement: 'bottom-right',
        backdrop: 'dynamic',
        backdropClasses:
            'bg-gray-900/50 dark:bg-gray-900/80 fixed inset-0 z-40',
        closable: true,
        onHide: () => {
            console.log('modal is hidden');
        },
        onShow: () => {
            console.log('modal is shown');
        },
        onToggle: () => {
            console.log('modal has been toggled');
        },
    };
  
    // instance options object
    const instanceOptions = {
      id: 'default-modal',
      override: true
    };

    const modal = new Modal($targetEl, options, instanceOptions);
    const btnModal = document.getElementsByClassName('open-modal')
    const btnCloseModal = document.getElementsByClassName('close-modal')
    if(btnModal.length > 0){
      btnModal[0].addEventListener('click', (e) => {
        e.preventDefault()
        modal.show()
      })
    }

    if(btnCloseModal.length > 0){
      btnCloseModal[0].addEventListener('click', (e) => {
        e.preventDefault()
        modal.hide()
      })
    }

    document.getElementById('btn_cam_nang').addEventListener('click', (e) => {
      e.preventDefault()
      const payload = {
        email: document.getElementById('email').value,
        fullname: document.getElementById('fullname').value,
        phone_number: document.getElementById('phone').value
      };
      fetch('/api/v1/members',{
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Client-id': 'ODISTAD120'
      },
      body: JSON.stringify(payload)
      }).then(response => response.json())
      .then(data => {
          if(data.error){
            document.getElementById('result').innerHTML = '<p class="text-center text-[#ff0000]">Đã có lỗi trong quá trình đăng ký, vui lòng thử lại sau</p>'
          }else{
            document.getElementById('result').innerHTML = '<p class="text-center text-secondary">Đăng ký thành công</p>'
          }
          
      })
      .catch(error => {
          console.error('Error:', error);
      });
      return false;
    })

    var elementsCollection = document.getElementsByClassName('phone')
    for(const element of elementsCollection) {
      element.addEventListener('input', function(event) {
        let input = event.target;
        var value = input.value;
        input.value = value.replace(/\D/g, '');
        if (input.value.length < 10 || input.value.length > 14){
          input.setCustomValidity('Số điện thoại phải từ 10 đến 14 kí tự')
          input.reportValidity()
        } else {
          document.getElementById('btn_new_employee').disabled = false;          
          input.setCustomValidity('');
        }
      });
    }
    var newEmployeeButon = document.getElementById('btn_new_employee')
    if (newEmployeeButon) {
      newEmployeeButon.addEventListener('click', (e) => {
        e.preventDefault()
        var form = document.getElementById('newEmployeeForm')
        if (!form.checkValidity()) {
          form.reportValidity()
        } else {
          const formData = new FormData();
          formData.append('email', document.getElementById('email').value.toString());
          formData.append('fullname', document.getElementById('fullname').value.toString());
          formData.append('phone_number', document.getElementById('phone').value.toString());
          formData.append('job_id', document.getElementById('jobId').value.toString());
          formData.append('profile', document.getElementById('profile').files[0]);
  
          console.log(formData)
  
          fetch('/api/v1/employees',{
            method: 'POST',
            headers: {
              'Accept': 'application/json',
              'Client-ID': 'SAGRI'
          },
          body: formData
          }).then(response => response.json())
          .then(data => {
              if(data.error){
                document.getElementById('result').innerHTML = '<p class="text-center text-[#ff0000]">Đã có lỗi trong quá trình nộp hồ sơ, vui lòng thử lại sau</p>'
              } else {
                document.getElementById('result').innerHTML = '<p class="text-center text-secondary">Nộp hồ sơ thành công</p>'
              }
              
          })
          .catch(error => {
              console.error('Error:', error);
          });
          return false;
        }
  
        if (value.length < 10 || value.length > 14){
          document.getElementById('result').innerHTML = '<p class="text-center text-[#ff0000]">Số điện thoại phải từ 10 đến 14 kí tự</p>'
        } 
      })
    }

    const openPopupBtn = document.getElementById('openPopupBtn');
    const popupContainer = document.getElementById('popupContainer');
    const closePopupBtn = document.getElementById('closePopupBtn');
    if (openPopupBtn) {
      openPopupBtn.addEventListener('click', () => {
        popupContainer.style.display = 'block'; // Show the popup container
      });
      closePopupBtn.addEventListener('click', () => {
        popupContainer.style.display = 'none'; // Hide the popup container
      });
      document.addEventListener('click', function(event) {
        if (!popupContainer.contains(event.target) && event.target !== openPopupBtn) {
          popupContainer.style.display = 'none'; // Hide the popup
        }
      });
    }
}
