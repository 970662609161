


const getSvgHtml = (svg) => {
  if (typeof window === 'undefined') return ''
  svg.setAttribute('width', '100%')
  svg.setAttribute('height', '100%')
  return svg.outerHTML
}


export default (editor, options = {}) => {
  const bm = editor.Blocks
  const { category, blocks, stylePrefix, flexGrid, rowHeight, addBasicStyle } = options;
  const clsRow = `gjs-row `;
  const clsCell = `gjs-cell`;

  const step = 0.2;
  const minDim = 1;
  const currentUnit = 1;
  const resizerBtm = {
    tl: 0,
    tc: 0,
    tr: 0,
    cl: 0,
    cr: 0,
    bl: 0,
    br: 0,
    minDim
  };
  const resizerRight = {
    ...resizerBtm,
    cr: 1,
    bc: 0,
    currentUnit,
    minDim,
    step
  };
  const rowAttr = {
    'data-gjs-droppable': `.${clsCell}`,
    'data-gjs-resizable': resizerBtm,
    'data-gjs-name': 'Row'
  };
  const colAttr = {
    'data-gjs-draggable': `.${clsRow}`,
    'data-gjs-resizable': resizerRight,
    'data-gjs-name': 'Cell'
  };

  const attrsToString = (attrs) => {
    const result = [];

    for (let key in attrs) {
      let value = attrs[key];
      const toParse = value instanceof Array || value instanceof Object;
      value = toParse ? JSON.stringify(value) : value;
      result.push(`${key}=${toParse ? `'${value}'` : `'${value}'`}`);
    }

    return result.length ? ` ${result.join(' ')}` : '';
  };
  const attrsRow = attrsToString(rowAttr);
  const attrsCell = attrsToString(colAttr);

  
  const sources = [
    {
      id: 'text-element-h1',
      class: '',
      label: 'Heading 1',
      content: { type: 'HEADING'},
      category: 'Element',
      order: 1
    },
    {
      id: 'section',
      class: '',
      label: 'Section',
      content: { type: 'SECTION'},
      category: 'Element',
      order: 1
    },{
      id: 'column',
      class: '',
      label: 'Column',
      content: { type: 'COLUMN'},
      category: 'Element',
      order: 1
    },
    {
      id: 'category',
      class: '',
      label: 'Category',
      content: { type: 'CATEGORY'},
      category: 'Element',
      order: 1
    },
    {
      id: 'faq',
      class: '',
      label: 'FAQ',
      content: { type: 'FAQ'},
      category: 'Element',
      order: 1
    },
    {
      id: 'bullet_list',
      class: '',
      label: 'BulletList',
      content: { type: 'BULLET_LIST'},
      category: 'Element',
      order: 1
    },
    {
      id: 'subscriber',
      class: '',
      label: 'Subscriber',
      content: { type: 'SUBSCRIBER'},
      category: 'Element',
      order: 1
    },
    {
      id: 'carousel',
      class: '',
      label: 'Carousel',
      content: { type: 'CAROUSEL'},
      category: 'Element',
      order: 1
    },
    {
      id: 'tabs',
      class: '',
      label: 'Tabs',
      content: { type: 'TABS'},
      category: 'Element',
      order: 1
    },
    {
      id: 'search',
      class: '',
      label: 'Search',
      content: { type: 'SEARCH'},
      category: 'Element',
      order: 1
    }
  ]
  
  sources.forEach((s) => {
    
    bm.add(s.id, {
      label: s.label,
      attributes: { class: `${s.class}` },
      content: s.content,
      category: { label: s.category, open: s.category === options.openCategory },
    })
  })
}
