import { Controller } from "@hotwired/stimulus"
import $ from 'jquery'

export default class extends Controller {
  static values = {
    resource: {type: String, default: 'bid_invitations'},
    input: String,
    perPage: {type: Number, default: 3}
  }

  async connect() {
    console.log('stimulus connected')
    var data = await this.fetchData()
    // console.log('this.dataValue', this.dataValue)

    this.buildHtml(data.items)
    $('.searchForm #dropdown .select-option').click(
      function(event) {
        const value = $(this).text().trim();
        this.resourceValue = event.target.value
        $('.searchForm #dropdown-button').text(value)
      }
    )
    
    $('.load-more').addClass('flex px-28')
    console.log('s',  $('.load-more'))
    $('.load-more > div:first-child').after(
      '<div><div class="w-[94px] pl-12 h-full flex items-center"><a href="https://sagri-black.vercel.app/" class="flex items-center text-base w-[94px] !leading-[19px] bg-inherit !text-[#4ECD00] font-semibold text-sm"><p class="inline w-[77px]">Xem Thêm</p><svg class="w-[17px] h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M14 5l7 7m0 0l-7 7m7-7H3"></path></svg></a> </div></div>')

    document.addEventListener('click', () => {
      var element =  $('.searchForm #dropdown')
      var button = $('.searchForm #dropdown-button')
   
      if ( !button.is(event.target) && button.has(event.target).length === 0) {
        element.hide()
      }
    } );

  }


  fetchData(per=this.perPageValue){
      var resource = this.resourceValue
      var inputValue = this.inputValue
      var url = `https://nhathaumgt.sagri.com.vn/v1/${resource}?page=1&per_page=3&order_attribute=published_at&q[code_or_bid_package_name_cont]=${inputValue}`
      return fetch(url ,{
        method: 'GET',
        headers: {
          "Accept": "application/json",
          "Content-Type": "application/json",
        }
      }).then(async res => {
        const data = await res.json()
        return data.data
      })
  }

  buildHtml(data){
    var dropdownSearch  = this.buildSearchDropdownElement()
    var table = this.buildTable(data)
    var html = dropdownSearch + table
    $('.dauthau-content').html(html)
  }

  buildTableContent(data){
    var stopReceiveDate = this.dateTimeConvert(data.stop_receive_at)
    var updatedAtDate = this.dateTimeConvert(data.updated_at)
    return `
      <tr>
        <td>
          <div class="code mb-1">${data.code}</div>
          <div class="name">${data.bid_package.name}</div>
          <div class="sub-name">${data.bid_package.investor_name}</div>
        </td>
        <td>
          <div class="date">${stopReceiveDate.day}/${stopReceiveDate.month}/${stopReceiveDate.year}</div>
          <div class="time">${stopReceiveDate.hours}:${stopReceiveDate.minutes}</div>
        </td>
        <td><span class="status">${data.status}</span></td>
        <td class="investor">${data.bid_package.investor_name}</td>
        <td>
          <div class="date">${updatedAtDate.day}/${updatedAtDate.month}/${updatedAtDate.year}</div>
          <div class="time">${updatedAtDate.hours}:${updatedAtDate.minutes}</div>
        </td>
      </tr>
    `
  }

  buildTable(data){
    var header = `<thead class="">
      <tr>
        <th>Tin mở thầu</th>
        <th>Thời điểm đóng thầu</th>
        <th>Trạng thái</th>
        <th>Chủ đầu tư</th>
        <th>Lần cuối chỉnh sửa</th>
      </tr>
    </thead>`
    var content = `<tbody>`
    data.forEach((item) => {
      content += this.buildTableContent(item)
    })

    content += `</tbody>`
    
    return `<div class='mx-[120px] h-[590px]' style="box-shadow: 0 0px 0px #919EAB1F; border-width: 1px; border-radius: 16px; overflow: hidden; "> <table class="table">` + header + content + `</table></div>`
  }

  dateTimeConvert(dateString){
    var date = new Date(dateString)
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-indexed in JS
    const year = date.getFullYear();
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
  
    return {
      day: day,
      month:  month,
      year: year,
      hours: hours,
      minutes: minutes
    } 
  }


  // Build search

  buildSearchDropdownElement(){

    return `<form class=" mx-auto pb-10 searchForm flex px-[120px] bg-[#F0F8F1] " >
          <div class="flex w-[1005px] h-[47px] border-[1px] rounded-r-lg ">
            <div class="flex flex-col ">
              <label for="search-dropdown" class="mb-2 text-sm font-medium text-gray-900 sr-only dark:text-white">Your Email</label>
              <button id="dropdown-button" data-action="click->dauthau#dropdown data-dropdown-toggle="dropdown" class="inline-flex rounded-none whitespace-nowrap items-center font-bold text-sm !leading-[22px] py-3 px-4 text-gray-900 bg-gray-100 rounded-s-lg hover:bg-gray-200 focus:ring-4 focus:outline-none focus:ring-gray-100 dark:bg-gray-700 dark:focus:ring-gray-700 dark:text-white" type="button">Thông Báo Mời Thầu
                <svg class="w-2.5 h-2.5 ms-2.5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
                  <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 4 4 4-4"/>
                </svg>
              </button>

              <div id="dropdown" class="z-10 bg-white hidden divide-y divide-gray-100 rounded-lg shadow w-44 dark:bg-gray-700">
                  <ul class="pt-1 text-sm text-gray-700 dark:text-gray-200" aria-labelledby="dropdown-button">
                  <li>
                      <button type="button" value='bid_invitations' class="select-option font-bold text-sm !leading-[22px] inline-flex w-full px-4 py-2 hover:bg-red-600 dark:hover:bg-gray-600 dark:hover:text-white">Thông Báo Mời Thầu</button>
                  </li>
                  <li>
                      <button type="button" value='contractor-selection-plans' class="select-option font-bold text-sm !leading-[22px] inline-flex w-full px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white">Kế Hoạch Lựa Chọn Nhà Thầu</button>
                  </li>
                  </ul>
              </div>
            </div>
            <div class="relative w-full h-full">
                <input data-action="click->dauthau#searchContent" class="search-input w-[751px] block w-full h-full rounded-none pl-4 z-20 text-sm text-gray-900 bg-gray-50 placeholder="..." required />
                <button type="submit" class="absolute top-0 end-0 py-[11px] px-[22px] end-0 w-[109px] text-sm font-medium h-full text-white rounded-r-lg bg-[#4ECD00] hover:bg-green-new focus:ring-4 ">
                  <span data-action="change->dauthau#setInput">Tìm Kiếm</span>
                </button>
            </div>
          </div>
          <div class='ml-4 w-44 h-12 border border-[#4ECD00] rounded-lg'>
            <button type="button" class="text-white bg-[#F0F8F1] rounded-lg h-full hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-bold rounded-lg text-sm dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800">
            <div class='items-center'>
              <p class='text-[#4ECD00]'>
                Tìm Kiếm Nâng Cao
              </p>
            </div>
            </button>
          </div>

      </form>`
  }

  setInput(event){
    console.log('event', event.target.value)
  }

  dropdown(){
    var element =  $('.searchForm #dropdown')
    if (element.is(":hidden")){
      element.show()
    } else {
      element.hide()
    }
    // $('.searchForm #dropdown').show()

  }




  async searchContent(event){
    console.log(' event.target.value',  event.target.value)
    // var data = await this.fetchData()

    // var table = this.buildTable(data.items)
    // $('.dauthau-content table.table').html(table)
  }

}
