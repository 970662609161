import 'odistad_favicon.png'
import 'odistad_hotline.png'
import 'odistad_messager.png'
import 'odistad_doctor.png'
import 'sagri-favicon-16x16.png'
import 'sagri-map.png'
import 'phone-call.png'
import 'signs-direction.png'
import 'envelopes.png'
import 'frame-592.png'
import 'linkedin.png'
import 'vector.png'
