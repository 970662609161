import { Controller } from "@hotwired/stimulus"
import { Drawer, Popover } from 'flowbite';
import $ from 'jquery'
import { get } from "@rails/request.js";
// Connects to data-controller="modals"
// options with default values
const options = {
  placement: 'right',
  backdrop: true,
  bodyScrolling: false,
  edge: true,
  edgeOffset: '',
  backdropClasses: 'bg-gray-900 bg-opacity-50 dark:bg-opacity-80 fixed inset-0 z-30',
  onHide: () => {
  },
  onShow: () => {
  },
  onToggle: () => {
  }
};

const optionsPopover = {
  placement: 'bottom',
  triggerType: 'click',
  offset: 10,
  onHide: () => {
  },
  onShow: () => {
  },
  onToggle: () => {
  }
};

let menuItemLabels = {
  'Categories': { label: 'categories', params: {per_page: '100'}, label_id: 'category' },
  'Posts': { label: 'posts', params: { post_type: 'post', per_page: '100' }, label_id: 'page'  },
  'Pages': { label: 'pages', params: {per_page: '100'}, label_id: 'page'  },
  'Tags':  { label: 'tags', params: {}, label_id: 'tag'  },
  'Job categories':  { label: 'job_categories', params: {}, label_id: 'job_category'  },
  'Galleries': { label: 'posts', params: { post_type: 'gallery', per_page: '100' }, label_id: 'page' }
}
export default class extends Controller {
  static targets = ['url', 'inputUrl', 'form', 'popoverContent', 'childVal']

  connect() {
    this.modal = new Drawer(document.getElementById('modal').children[0], options)
    this.modal.show()
    this.selectField = $('#menu_item_menu_item_type')
    this.selectField.select2()
    // set the popover content element
    const $targetEl = document.getElementById('popover-click');
    // set the element that trigger the popover using hover or click
    const $triggerEl = document.getElementById('test');
    console.log('inside childVal')
    // options with default values
    this.popover = new Popover($targetEl, $triggerEl, optionsPopover);
    this.selectField.on('select2:select', (e) => {
      let data = e.params.data
      const classes = ['hidden']

      if (this.childValTarget.classList.contains('hidden')){
        this.childValTarget.classList.remove(...classes)
      }
      let label = $('label#child-val-label')
      var childVal = $('#childVal')

      label.empty();
      if (data.text != 'Homepage'){
        label.append(data.text);
      }
      let dataLabel = data.text.toLocaleLowerCase().replace(/ /g,'-')
      switch (dataLabel){
      case 'redirect-url':
        this.removeExist()
        childVal.append(`
          <input class=" child-val-option bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500" placeholder="Paste a link here" required="required" data-modals-target="inputUrl" type="text" value="/" name="menu_item[url]" id="menu_item_url">
        `)
        break
      case 'homepage':
        break
      default:
        this.removeExist()
        let menuItemLabel = menuItemLabels[`${data.text}`]

        let label_id = menuItemLabel.label_id
        childVal.append(`<select name=menu_item[${label_id}_id] id='menu_item_${label_id}_id' class=" child-val-option bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" required">
          <option value="" label=" "></option>
        `)
        this.getData(menuItemLabel.label, menuItemLabel.params)
        break
      }
    })
  }

  removeExist(){
    $('input.child-val-option').remove()
    $('select.child-val-option').remove()
  }

  createSelectOption(data){
    var options = $('select.child-val-option')

    if (data == []) return
    let dataLength = data.length
    let optionsString = ''
    if (dataLength == 0) return
    for (let i = 0; i < dataLength; i++) {
      let val 
      if (data[i].title == undefined){
        val = data[i].name
      } else {
        val = data[i].title
      }
      optionsString += `<option value=${data[i].id}> ${val}</option>`
    }
    optionsString += '</select>'
    options.append(optionsString)
  }

  getData(title, params='') {
    var client = $('.hidden-client-id').val()
    let url = `${window.location.origin}/api/v1/`
    if (title != 'job_categories'){
      url += 'content'
    }
    url += `/${title}?${new URLSearchParams(params)}`
    const req = fetch(url ,{
      method: 'GET',
      headers: {
        "Accept": "application/json",
        "Content-Type": "application/json",
        "CLIENT_ID": client,
      }
    })
    req.then(res => {
      return res.json()
    }).then(data => {
      this.createSelectOption(data.data.items)
    })
  }

  submit(e) {
    e.preventDefault()
    this.formTarget.submit()

  }
  close(e) {
    e.preventDefault()
    this.modal.close()
  }
}

