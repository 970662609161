import { Controller } from "@hotwired/stimulus"
import $ from 'jquery'

// Connects to data-controller="metadata"
export default class extends Controller {
  static targets = [
    'seoTitle','seoLink', 'seoDesc', 'seoPreviewTitle', 'seoPreviewLink', 'seoPreviewDesc', 'seoTitleCounter', 'seoDescCounter',//SEO METADATA
    'fbTitle','fbLink', 'fbDesc', 'fbPreviewTitle', 'fbPreviewLink', 'fbPreviewDesc', 'fbTitleCounter', 'fbDescCounter',//Facebook CARD
    'xTitle','xLink', 'xDesc', 'xPreviewTitle', 'xPreviewLink', 'xPreviewDesc', 'xTitleCounter', 'xDescCounter', //X CARD
    'desc', 'descCounter'

  ]
  connect() {
    this.updateMetadata(this.descTarget, null, this.descCounterTarget)
    this.updateMetadata(this.seoTitleTarget, this.seoPreviewTitleTarget, this.seoTitleCounterTarget)
    this.updateMetadata(this.seoLinkTarget, this.seoPreviewLinkTarget)
    this.updateMetadata(this.seoDescTarget, this.seoPreviewDescTarget, this.seoDescCounterTarget)

    this.updateMetadata(this.fbTitleTarget, this.fbPreviewTitleTarget,this.fbTitleCounterTarget)
    this.updateMetadata(this.fbDescTarget, this.fbPreviewDescTarget,this.fbDescCounterTarget)

    this.updateMetadata(this.xTitleTarget, this.xPreviewTitleTarget, this.xTitleCounterTarget)
    this.updateMetadata(this.xDescTarget, this.xPreviewDescTarget,this.xDescCounterTarget)
    this.multipleSelect()
  }

  updateMetadata(target, targetPreview, counter){
    if(targetPreview)
      targetPreview.innerHTML = target.value
    if(counter)
      counter.innerHTML = target.value.length + ''
    target.addEventListener('keyup', () => {
      if(targetPreview)
        targetPreview.innerHTML = target.value
      if(counter)
        counter.innerHTML = target.value.length + ''
    })
  }

  multipleSelect(){
    console.log('multiple')

    $('select#page_category_ids').select2()
    $('.select2-container--default .select2-selection--multiple ').css({
      'border-color': 'rgb(209, 213, 219)',
    })
    $('.select2-search--inline .select2-search__field').css({
      'box-shadow': 'unset',
    })
  }
}
